<template>
    <Page icon="mdi-account-group" :title="form.name" :progress="progress" :error="error" @reload="load()">
        <ActionBar :updateTime="updateTime">
            <v-btn 
                color="primary"
                @click="save()"
                :loading="saveProgress" 
                :disabled="saveProgress || dangerProgress"
            >
                <v-icon left>mdi-content-save-outline</v-icon> {{ $t("save") }}
            </v-btn>
        </ActionBar>

        <v-row>
            <v-col class="col-12 mt-3 mb-0">
                <Card :title="$t('role.update.title')">
                    <v-row>
                        <v-col class="col-12 col-md-8">
                            <v-text-field
                                :label="$t('role.form.name')"
                                v-model="form.name"
                                :error-messages="errors.name"
                                :hide-details="!errors.name"
                                :disabled="saveProgress || dangerProgress"
                                @change="validate('name')"
                                outlined
                                prepend-inner-icon="mdi-account-group"
                            />
                        </v-col>
                    
                        <v-col class="col-12 col-md-4">
                            <v-select
                                :label="$t('role.form.default')" 
                                item-text=text
                                item-value="value"
                                v-model="form.default"
                                :error-messages="errors.default"
                                :hide-details="!errors.default"
                                :items="defaultRole"
                                :disabled="(form.default && initDefault) || saveProgress || dangerProgress"
                                outlined
                                prepend-inner-icon="mdi-check-circle"
                            />
                        </v-col>
                    </v-row>

                    <v-text-field
                        class="mt-3"
                        :label="$t('role.form.info')"
                        v-model="form.info"
                        :error-messages="errors.info"
                        :hide-details="!errors.info"
                        :disabled="saveProgress || dangerProgress"
                        @change="validate('info')"
                        outlined
                        prepend-inner-icon="mdi-clipboard-text-outline"
                    ></v-text-field>
                </Card>
            </v-col>
        </v-row>

        <v-row class="mt-0 mb-0">
            <v-col class="col-12 col-md-6 pb-md-0">
                <Card :title="$t('role.update.perms_add')" height="100%">
                    <v-simple-table v-if="form.perms">
                        <thead>
                            <tr>
                                <th class="font-weight-black">
                                    {{ $t("role.update.perm_name") }}
                                </th>

                                <th class="font-weight-black w-action">
                                    {{ $t("role.update.add") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(p, index) in userPerms" :key="index">
                            <tr v-if="permNoExist(p)">
                                <td>
                                    <span>{{ $t(`perms.${p}`) }}</span>
                                </td>
                                <td v-if="permNoExist(p)" class="w-action">
                                    <v-btn 
                                        text
                                        color="success" 
                                        @click="addPerm(p)"
                                        :disabled="!permNoExist(p) || saveProgress || dangerProgress || form.perms.find(x => x == 'all') != null"
                                    >
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <v-spacer />
                        </tbody>
                    </v-simple-table>
                </Card>
            </v-col>

            <v-col class="col-12 col-md-6 pt-0 pt-md-3 pb-0">
                <Card :title="$t('role.update.perms')" height="100%">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="font-weight-black">
                                    {{ $t("role.update.perm_name") }}
                                </th>
                                <th class="font-weight-black w-action">
                                    {{ $t("role.update.delete") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(p, index) in form.perms" :key="index">
                            <tr>
                                <td>
                                    <span>{{ $t(`perms.${p}`) }}</span>
                                </td>

                                <td class="w-action">
                                    <v-btn
                                        :disabled="isUserRoleAll || saveProgress  || dangerProgress"
                                        v-if="user.hasPerm(p)"
                                        text
                                        color="error" 
                                        @click="remove(p)"
                                    >
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <v-spacer />
                        </tbody>
                    </v-simple-table>
                </Card>
            </v-col>
        </v-row>

        <DangerZone
            v-if="user.role._id != form.id && user.hasPerm('user')"
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'changeStatus',
                    itemName: $t('role.danger_zone.change_status'),
                    itemInfo: $t('role.danger_zone.change_status_info') + ' ',
                    itemInfoGreen: (form.enabled ? $t('active') : null),
                    itemInfoRed: (form.enabled ? null : $t('inactive')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false)
                },

                {
                    itemKey: 'delete',
                    itemName: $t('role.danger_zone.delete'),
                    itemInfo: $t('role.danger_zone.delete_info'),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                }
            ]"
            :disabled="saveProgress || dangerProgress"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('role.danger_zone.change_status');
                    break;
                case 'delete':
                    return $t('role.danger_zone.delete');
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('role.danger_zone.dialog.change_status_desc');
                    break;
                case 'delete':
                    return $t('role.danger_zone.dialog.delete_desc');
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('inactive') : $t('active'));
                    break;
                case 'delete':
                    return form.name;
            }}"
            :checkText="() => form.name"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        />

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import Card from "./components/Card.vue";
import DangerZone from "./components/DangerZone.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import validator from "../plugins/validator";
import * as roleValidation from "../api/role/validation";
import SaveSnackBar from "./components/SaveSnackBar.vue";

export default {
    components: {
        Page,
        Card,
        ActionBar,
        DangerZone,
        DangerZoneConfirm,
        SaveSnackBar
    },

    async mounted() {
        this.userPerms = this.$store.state.api.user.data.role.perms;
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: (vm) => ({
        progress: true,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,

        error: {
            type: null,
            msg: null,
            code: null
        },

        form: {
            id: null,
            name: null,
            default: null,
            enabled: null,
            perms: null
        },

        errors: {
            name: null,
            default: null,
            perms: null
        },

        userPerms: null,
        updateTime: null,

        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,
        
        defaultRole: [
            {
                value: true,
                text: vm.$t("yes")
            },
            {
                value: false,
                text: vm.$t("no")
            }
        ],
        initDefault: false,
        save_snackbar: false,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        },

        isUserRoleAll(){
            return this.user.hasPerm("all") && this.form.id == this.user.role._id; 
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/role/get", {id: this.form.id});
            if (res.status == 200) {
                this.form.perms = res.data.role.perms;
                this.userPerms = res.data.perms;
                this.form.name = res.data.role.name;
                this.form.enabled = res.data.role.enabled;
                this.form.default = res.data.role.default;
                this.form.info = res.data.role.info;
                this.updateTime = res.data.role.updateTime ? new Date(res.data.role.updateTime) : null;
                this.initDefault = res.data.role.default;
                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
        },

        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                roleValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        remove(p){
            this.form.perms = this.form.perms.filter(item => item !== p);
        },

        permNoExist(p) {
            if (this.form.perms.indexOf(p) == -1) {
                return true;
            }
        },

        addPerm(p){
            this.form.perms.push(p);
            if(this.form.perms.find(x => x == 'all')) this.form.perms = ["all"];
        },

        async save() {
            if(!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/role/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;
                    const res = await this.$store.dispatch("api/role/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$router.push({ name: 'roles' })
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/role/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        }
    }
};
</script>
