import Joi from "joi";

export const create = Joi.object({
    entity: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    default: Joi.boolean()
        .optional(),
});

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    perms: Joi.array()
        .required(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    default: Joi.boolean()
        .optional(),
    info: Joi.string()
        .max(255)
        .trim()
        .optional()
        .allow(null, ""),
    enabled: Joi.boolean()
        .optional()
});
